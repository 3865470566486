import { createStore } from 'vuex'
import axios from "axios";
import { BaseAthlete } from "@/types/BaseAthletes"


const main = {
  state: () => ({
    main: null,
  }),

  actions: {
    async setMain(state, meetid) {
      if (meetid) {
        const url = "https://live.swimrankings.net/meets/" + meetid + "/main.json"
        await axios.get(
          url,
          { params: { t: new Date().getTime() } }
        ).then((response) => {
          state.commit("setMain", response.data);
        }).catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            //console.log('Error', error.message);
          }
          // console.log(error.config);
          state.commit("setMain", null);
        });
      }
    },
  },

  mutations: {
    setMain(state, payload) {
      state.main = payload;
    },
  },

  getters: {
    getMain(state) {
      return state.main;
    },
  }
}

const clubs = {
  state: () => ({
    clubs: null,
  }),

  actions: {
    async setClubs(state, meetid) {
      if (meetid) {
        const url = "https://live.swimrankings.net/meets/" + meetid + "/clubs.json";
        await axios.get(
          url,
          { params: { t: new Date().getTime() } }
        ).then((response) => {
          state.commit("setClubs", response.data);
        }).catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            //console.log('Error', error.message);
          }
          // console.log(error.config);
          state.commit("setClubs", null);
        });
      }
    },
  },

  mutations: {
    setClubs(state, payload) {
      state.clubs = payload;
    },
  },

  getters: {
    getClubs(state) {
      return state.clubs;
    },
  }
}

const athletes = {
  state: () => ({
    athletes: null,
  }),

  actions: {
    async setAthletes(state, meetid) {
      if (meetid) {
        const url = "https://live.swimrankings.net/meets/" + meetid + "/athletes.json"
        //console.log(url)
        await axios.get(
          url,
          { params: { t: new Date().getTime() } }
        ).then((response) => {
          state.commit("setAthletes", response.data);
        }).catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            //console.log('Error', error.message);
          }
          // console.log(error.config);
          state.commit("setAthletes", null);
        });
      }
    },
  },

  mutations: {
    setAthletes(state, payload) {
      state.athletes = payload;
    },
  },

  getters: {
    getAthletes(state) {
      return state.athletes;
    },
  }
}

const events = {
  state: () => ({
    events: null,
  }),

  actions: {
    async setEvents(state, meetid) {
      if (meetid) {
        const url = "https://live.swimrankings.net/meets/" + meetid + "/events.json";
        await axios.get(
          url,
          { params: { t: new Date().getTime() } }
        ).then((response) => {
          state.commit("setEvents", response.data);
        }).catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            //console.log('Error', error.message);
          }
          // console.log(error.config);
          state.commit("setEvents", null);
        });
      }
    },
  },

  mutations: {
    setEvents(state, payload) {
      state.events = payload;
    },
  },

  getters: {
    getEvents(state) {
      return state.events;
    },
  }
}

const eventsBySession = {
  state: () => ({
    eventsBySession: null,
  }),

  actions: {
    async setEventsBySession(state, meetid) {
      if (meetid) {
        const url = "https://live.swimrankings.net/meets/" + meetid + "/eventsBySession.json";
        axios.get(
          url,
          { params: { t: new Date().getTime() } }
        ).then((response) => {
          state.commit("setEventsBySession", response.data);
        }).catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            //console.log('Error', error.message);
          }
          // console.log(error.config);
          state.commit("setEventsBySession", null);
        });
      }
    },
  },

  mutations: {
    setEventsBySession(state, payload) {
      state.eventsBySession = payload;
    },
  },

  getters: {
    getEventsBySession(state) {
      return state.eventsBySession;
    },
  }
}

const agegroups = {
  state: () => ({
    agegroups: null,
  }),

  actions: {
    async setAgegroups(state, meetid) {
      if (meetid) {
        const url = "https://live.swimrankings.net/meets/" + meetid + "/agegroups.json";
        await axios.get(
          url,
          { params: { t: new Date().getTime() } }
        ).then((response) => {
          state.commit("setAgegroups", response.data);
        }).catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            //console.log('Error', error.message);
          }
          // console.log(error.config);
          state.commit("setAgegroups", null);
        });
      }
    },
  },

  mutations: {
    setAgegroups(state, payload) {
      state.agegroups = payload;
    },
  },

  getters: {
    getAgegroups(state) {
      return state.agegroups;
    },
  }
}

const favorites = {
  state: () => ({
    favorites: [] as Array<BaseAthlete>,
  }),

  actions: {
    async addFavorite(state, athlete: BaseAthlete) {
      state.commit("addFavorite", athlete);
    },
    async removeFavorite(state, athlete: BaseAthlete) {
      state.commit("removeFavorite", athlete);
    },
    async initialiseStore(state) {
      state.commit("initialiseStore");
    },
  },

  mutations: {
    addFavorite(state, base_athlete: BaseAthlete) {
      let inFavoriteList = false
      let replaceAthlete = false
      for (let i = 0; i < state.favorites.length; i++) {
        if ('swrid' in state.favorites[i] && state.favorites[i].swrid == base_athlete.swrid) {
          inFavoriteList = true;
          break;
        }
        else if ((state.favorites[i].fullname == base_athlete.fullname) && (state.favorites[i].yob == base_athlete.yob)) {
          state.favorites[i] = base_athlete;
          if ('swrid' in base_athlete) {
            replaceAthlete = true;
          }
          inFavoriteList = true;
          break;
        }
      }
      if (!inFavoriteList) {
        state.favorites.push(base_athlete);
      }
      if (!inFavoriteList || replaceAthlete) {
        localStorage.favorites = JSON.stringify(state.favorites);
      }
    },
    removeFavorite(state, base_athlete: BaseAthlete) {
      for (let i = 0; i < state.favorites.length; i++) {
        if (state.favorites[i].swrid == base_athlete.swrid || ((state.favorites[i].fullname == base_athlete.fullname) && (state.favorites[i].yob == base_athlete.yob))) {
          state.favorites.splice(i, 1);
        }
      }
      localStorage.favorites = JSON.stringify(state.favorites);
    },
    initialiseStore(state) {
      // Check if the ID exists
      if (localStorage.favorites) {
        state.favorites = JSON.parse(localStorage.favorites);
      }
    }
  },

  getters: {
    getFavorites(state) {
      return state.favorites;
    },
  }
}

const clubTab = {
  state: () => ({
    clubTab: "",
  }),


  actions: {
    async setClubTab(state, clubTabData: string) {
      state.commit("setClubTab", clubTabData);
    },
  },


  mutations: {
    setClubTab(state, payload: string) {
      state.clubTab = payload;
    },
  },

  getters: {
    getClubTab(state) {
      return state.clubTab;
    },
  }
}

const contentPaddingTop = {
  state: () => ({
    contentPaddingTop: 0,
  }),


  actions: {
    async setContentPaddingTop(state, contentPaddingTopData: number) {
      state.commit("setContentPaddingTop", contentPaddingTopData);
    },
  },


  mutations: {
    setContentPaddingTop(state, payload: number) {
      state.contentPaddingTop = payload;
    },
  },

  getters: {
    getContentPaddingTop(state): number {
      return state.contentPaddingTop;
    },
  }
}



export const store = createStore({
  modules: {
    main,
    clubs,
    athletes,
    events,
    eventsBySession,
    agegroups,
    favorites,
    clubTab,
    contentPaddingTop
  }
});