import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationTop = _resolveComponent("NavigationTop")!
  const _component_Waves = _resolveComponent("Waves")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_NavigationBottom = _resolveComponent("NavigationBottom")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavigationTop, {
      insetTop: _ctx.insetTop,
      navigationHeight: _ctx.topNavigationHeight
    }, null, 8, ["insetTop", "navigationHeight"]),
    (_ctx.scrollpx == 0 && (_ctx.$route.path.includes('/meets') || _ctx.$route.path == '/'))
      ? (_openBlock(), _createBlock(_component_Waves, {
          key: 0,
          class: "waves-top",
          isNativePlatform: _ctx.isNativePlatform
        }, null, 8, ["isNativePlatform"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view, { class: "content" }),
    _createVNode(_component_NavigationBottom, {
      insetBottom: _ctx.insetBottom,
      navigationHeight: _ctx.bottomNavigationHeight,
      isIos: _ctx.isIos
    }, null, 8, ["insetBottom", "navigationHeight", "isIos"])
  ], 64))
}