import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navbar" }
const _hoisted_2 = { style: {"padding-right":"50px"} }
const _hoisted_3 = { class: "swimstyle" }
const _hoisted_4 = { class: "move" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "top-bar" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        to: "/swimstats",
        style: {"color":"red"}
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("swimstats live")
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isContained('/meets') || this.$route.path == '/')
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              style: {"padding-right":"20px"},
              to: "/meets"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Meets")
              ])),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              style: {"padding-right":"20px"},
              to: "/meets"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Meets")
              ])),
              _: 1
            }))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.get_text()), 1)
      ])
    ])
  ], 64))
}