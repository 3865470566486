import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "navbar navbar-offset" }
const _hoisted_2 = { class: "navbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (
        _ctx.isContained('/resultsrelay/') ||
        _ctx.isContained('/entriesrelay/') ||
        _ctx.isContained('/heatsrelay/') ||
        _ctx.isContained('/teamnumber/')
      )
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_router_link, {
              class: "linkbox",
              to: { name: 'Entriesrelay' }
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Entries")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              class: "linkbox",
              to: { name: 'Heatsrelay' }
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Heats")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              class: "linkbox",
              to: { name: 'Resultsrelay' }
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Results")
              ])),
              _: 1
            }),
            (_ctx.isContained('teamnumber'))
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  class: "linkbox",
                  to: { name: 'Resultrelay' }
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Details")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (
          ('eventid' in _ctx.$route.params &&
            _ctx.$route.params.eventid != '0' &&
            'clubid' in _ctx.$route.params) ||
          ('athleteid' in _ctx.$route.params &&
            _ctx.$route.params.eventid != '0' &&
            'clubid' in _ctx.$route.params) ||
          'teamnumber' in _ctx.$route.params
        )
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_router_link, {
                    class: "linkbox",
                    to: { name: 'Entries' }
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Entries")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_router_link, {
                    class: "linkbox",
                    to: { name: 'Heats' }
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Heats")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_router_link, {
                    class: "linkbox",
                    to: { name: 'Results' }
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("Results")
                    ])),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true),
            (
          'athleteid' in _ctx.$route.params &&
          _ctx.$route.params.athleteid != '0' &&
          'clubid' in _ctx.$route.params
        )
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  class: "linkbox",
                  to: { name: 'Result' }
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode("Details")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 64))
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isContained('/meet/'))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.isIos)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "iosinsert linkbox",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args))),
                  style: {"color":"rgb(102, 101, 101)"}
                }, " ❮ "))
              : _createCommentVNode("", true),
            _createVNode(_component_router_link, {
              class: "linkbox",
              to: { name: 'Meet' }
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("Meet")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              class: "linkbox",
              to: { name: 'Schedule' }
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("Schedule")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              class: "linkbox",
              to: { name: 'Clubs' }
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("Clubs")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              class: "linkbox",
              to: { name: 'Live' }
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("Live")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              class: "linkbox",
              to: { name: 'Favorites' }
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode(" ☆ ")
              ])),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "base-bar" }, null, -1))
  ], 64))
}