<template>
  <div class="navbar navbar-offset">
    <template
      v-if="
        isContained('/resultsrelay/') ||
        isContained('/entriesrelay/') ||
        isContained('/heatsrelay/') ||
        isContained('/teamnumber/')
      "
    >
      <router-link class="linkbox" :to="{ name: 'Entriesrelay' }">Entries</router-link>
      <router-link class="linkbox" :to="{ name: 'Heatsrelay' }">Heats</router-link>
      <router-link class="linkbox" :to="{ name: 'Resultsrelay' }">Results</router-link>
      <router-link
        class="linkbox"
        v-if="isContained('teamnumber')"
        :to="{ name: 'Resultrelay' }"
        >Details</router-link
      >
    </template>
    <template v-else>
      <template
        v-if="
          ('eventid' in $route.params &&
            $route.params.eventid != '0' &&
            'clubid' in $route.params) ||
          ('athleteid' in $route.params &&
            $route.params.eventid != '0' &&
            'clubid' in $route.params) ||
          'teamnumber' in $route.params
        "
      >
        <router-link class="linkbox" :to="{ name: 'Entries' }">Entries</router-link>
        <router-link class="linkbox" :to="{ name: 'Heats' }">Heats</router-link>
        <router-link class="linkbox" :to="{ name: 'Results' }">Results</router-link>
      </template>
      <template
        v-if="
          'athleteid' in $route.params &&
          $route.params.athleteid != '0' &&
          'clubid' in $route.params
        "
      >
        <router-link class="linkbox" :to="{ name: 'Result' }">Details</router-link>
      </template>

      <!-- uncomment to make the 2nd level bottom menu appear again -->
      <!-- <template
        v-if="
          'athleteid' in $route.params &&
          $route.params.athleteid != '0' &&
          'clubid' in $route.params
        "
      >
        <router-link class="linkbox" :to="{ name: 'Athlete' }">Athlete</router-link>
      </template> -->

      <!-- uncomment to make the 2nd level bottom menu appear again -->
      <!-- <template
        v-if="
          'athleteid' in $route.params &&
          $route.params.athleteid == '0' &&
          'clubid' in $route.params &&
          $route.params.clubid != '0'
        "
      >
        <router-link class="linkbox" :to="{ name: 'Club' }">{{
          get_club_name()
        }}</router-link>
      </template> -->
    </template>
  </div>
  <div class="navbar">
    <template v-if="isContained('/meet/')">
      <div
        v-if="isIos"
        class="iosinsert linkbox"
        @click="goBack"
        style="color: rgb(102, 101, 101)"
      >
        ❮
      </div>

      <router-link class="linkbox" :to="{ name: 'Meet' }">Meet</router-link>
      <router-link class="linkbox" :to="{ name: 'Schedule' }">Schedule</router-link>
      <router-link class="linkbox" :to="{ name: 'Clubs' }">Clubs</router-link>
      <router-link class="linkbox" :to="{ name: 'Live' }">Live</router-link>
      <router-link class="linkbox" :to="{ name: 'Favorites' }"> ☆ </router-link>
    </template>
  </div>
  <div class="base-bar"></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import meetlookup from "../MeetLookup";

export default defineComponent({
  name: "NavigationBottom",
  mixins: [meetlookup],
  data: function () {
    return {
      showNavbar: false,
      lastScrollPosition: 0,
    };
  },
  props: {
    insetBottom: {
      type: Number,
      required: true,
    },
    navigationHeight: {
      type: Number,
      required: true,
    },
    isIos: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    isContained(mystr: string) {
      return this.$route.path.includes(mystr);
    },
    goBack() {
      this.$router.go(-1);
    },
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return;
      }
      // Here we determine whether we need to show or hide the navbar
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      // Set the current scroll position as the last scroll position
      this.lastScrollPosition = currentScrollPosition;
    },
    get_club_name() {
      if ("clubid" in this.$route.params && this.clubs) {
        for (let idx = 0; idx < this.clubs.length; idx++) {
          if (this.clubs[idx].id == this.$route.params["clubid"]) {
            return this.clubs[idx].shortname;
          }
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
});
</script>

<style scoped>
.navbar {
  display: flex;
  background-color: rgb(204, 204, 204);
  border-top-color: rgb(34, 34, 34);
  overflow: hidden;
  position: fixed;
  bottom: v-bind(insetBottom + "px");
  width: 100%;
  max-width: 800px;
  text-align: center;
  font-size: 11px;
  z-index: 10;
}

.navbar-offset {
  bottom: v-bind(insetBottom + navigationHeight + "px");
}

a {
  color: rgb(68, 63, 63);
  text-decoration: none;
}

/* Style the links inside the navigation bar */
.navbar a {
  display: block;
  text-align: center;
  line-height: v-bind(navigationHeight + "px");
  text-decoration: none;
  font-weight: bold;
}

.iosinsert {
  display: block;
  text-align: center;
  line-height: v-bind(navigationHeight + "px");
  text-decoration: none;
  font-weight: bold;
}

.linkbox {
  border-color: rgb(2, 2, 2);
  border-style: solid;
  flex-basis: 100%;
  border-right-width: 1px;
  border-left-width: 1px;
  border-top-width: 2px;
  border-bottom-width: 1px;
}

.router-link-active {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  font-size: 14px;
}

.base-bar {
  height: v-bind(insetBottom + "px");
  bottom: 0px;
  display: flex;
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 800px;
  text-align: center;
  z-index: 10;
  background-color: rgb(179, 179, 179);
}
</style>
