import { defineComponent } from 'vue';
import lookup from "@/assets/splashme/lookup.json"
import { BaseMain } from "@/types/BaseMain"
import { BaseEventsBySessions, BaseEvent } from "@/types/BaseEventsBySessions"
import { BaseClub } from "@/types/BaseClubs"
import { BaseAthlete } from "@/types/BaseAthletes"
import { Result } from "@/types/EventResults"



export default defineComponent({
  methods: {
    get_date_from_event_id(event_id: string) {
      return this.events[event_id].date;
    },
    get_style_from_event_id(id: string): string {
      if (this.events) {
        return (
          this.events[id].distance + " " + lookup.strokes[this.events[id].stroke]
        );
      }
      return "";
    },
    get_style_short_from_event_id(event_id: string): string {
      return (
        this.events[event_id].distance +
        " " +
        lookup.strokes_short[this.events[event_id].stroke]
      );
    },
    get_agegroup_name_from_agegroup_id(agegroup_id: string | undefined): string {
      if (!agegroup_id) {
        return "undef";
      }
      let tmp: string;
      // eslint-disable-next-line no-prototype-builtins
      if (this.agegroups[agegroup_id].hasOwnProperty("key")) {
        tmp = lookup.agegroups[this.agegroups[agegroup_id].key]
          .replace("#MIN#", this.agegroups[agegroup_id].min)
          .replace("#MAX#", this.agegroups[agegroup_id].max);
      } else {
        tmp = this.agegroups[agegroup_id].name;
      }
      return tmp;
    },
    get_yob_from_result(result: Result): number {
      return parseInt(result.agetext);
    },
    get_gender_from_event_id(event_id: string): string {
      try {
        return lookup.genders[this.events[event_id].gender];
      } catch (e) {
        return 'undef';
      }
    },
    get_course_from_course_id(course_id: string): string {
      return lookup.courses[course_id];
    },
    get_course_label_from_course_id(course_id: string): string {
      if (lookup.courses[course_id] == '50m')
      {
        return 'LCM'
      } else if (lookup.courses[course_id] == '25m'){
        return 'SCM'
      }
      else{
        return 'lookup.courses[course_id]'
      }
    },
    get_seconds_from_swimrankingstime(swimrankingstime: string): number {
      const tmp = swimrankingstime
        .split(":")
        .join(".")
        .split(".");
      if (tmp.length == 2) {
        return parseInt(tmp[0]) + parseInt(tmp[1]) / 100;
      } else if (tmp.length == 3) {
        return (
          parseInt(tmp[0]) * 60 + parseInt(tmp[1]) + parseInt(tmp[2]) / 100
        );
      } else {
        return NaN;
      }
    },
    get_event_number_from_event_id(event_id: string): number {
      try {
        return this.events[event_id].number;
      } catch (e) {
        return NaN;
      }
    },
    get_time_from_event_id(event_id: string): string {
      try {
        if (this.events[event_id].time == '') {
          return '--:--'
        }
        else {
          return this.events[event_id].time;
        }
      }
      catch (e) {
        return '--:--';
      }
    },
    get_weekday_from_event_id(event_id: string): string {
      try {
        const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
        const d = new Date(this.events[event_id].date);
        return days[d.getDay()];
      } catch (e) {
        return '--.';
      }
    },
    get_dsq_code_from_status(status: string | undefined): string {
      if (!status) {
        return "undef";
      }
      return lookup.resultstati[status];
    },
    get_event_status_from_event_id(event_id: string): number {
      for (let i = 0; i < this.eventsBySession.sessions.length; i++) {
        for (
          let k = 0;
          k < this.eventsBySession.sessions[i].events.length;
          k++
        ) {
          if (this.eventsBySession.sessions[i].events[k].id == event_id) {
            return this.eventsBySession.sessions[i].events[k].status;
          }
        }
      }
      return NaN;
    },
    get_emoji_from_age_and_gender(age: number, gender: number): string {
      const textArea = document.createElement("textarea");
      let a = "&#x1F467";
      if (gender == 2) {
        if (age < 16) {
          a = "&#x1F467";
        } else {
          a = "&#x1F469";
        }
        a = "&#128308"
      }
      if (gender == 1) {
        if (age < 16) {
          a = "&#x1F466";
        } else {
          a = "&#x1F468";
        }
        a = "&#128309"
      }
      textArea.innerHTML = a;
      return textArea.value;
    },
    get_image_src_from_gender(gender: number): string {
      // https://icons8.com/icons/set/male-female
      if (gender == 1) {
        return require("@/assets/gender1.png");
      }
      if (gender == 2) {
        return require("@/assets/gender2.png");
      }
      return '';
    },
    get_image_src_from_event_status(event_status: number): string {
      /*
      0: None     - wenn leer, keine Meldungen
      1: Entries   - wenn Meldungen vorhanden
      2: Seeded  - wenn Startliste gerechnet (Läufe vorhanden)
      3: Running  - wenn gestartet.
      4: Unofficial  - wenn mindestens eine Endzeit vorhanden
      5: Official   - wenn abgeschlossen, Ergebnisse alle auf "offiziell" gesetzt.
      */
      if (event_status == 0) {
        return require("@/assets/upcoming.png");
      }
      if (event_status == 2 || event_status == 1) {
        return require("@/assets/seeded.png");
      }
      if (event_status == 3) {
        return require("@/assets/running.gif");
      }
      if (event_status == 5 || event_status == 4) {
        return require("@/assets/finished.png");
      }
      return require("@/assets/upcoming.png");
    },
    get_performance(swimtime: string, entrytime: string): number | string {
      const performance = Math.round(
        ((this.get_seconds_from_swimrankingstime(entrytime) *
          this.get_seconds_from_swimrankingstime(entrytime)) /
          (this.get_seconds_from_swimrankingstime(swimtime) *
            this.get_seconds_from_swimrankingstime(swimtime))) *
        100
      );
      if (Number.isNaN(performance)) {
        return "---";
      }
      return performance;
    },
    get_round_name_from_event_id(event_id: string): string {
      try {
        // eslint-disable-next-line no-prototype-builtins
        return lookup.rounds[this.events[event_id].round];
      } catch (e) {
        return "?";
      }
    },
    get_isrelay_from_event_id(event_id: string): boolean {
      try {
        // eslint-disable-next-line no-prototype-builtins
        return this.events[event_id].isrelay;
      } catch (e) {
        return false;
      }
    },
    get_last_name_from_athlete_id(athlete_id: string): string {
      for (let i = 0; i < this.athletes.length; i++) {
        if (this.athletes[i].id == athlete_id) {
          return this.athletes[i].lastname;
        }
      }
      return "";
    },
    get_full_name_from_athlete_id(athlete_id: string): string {
      for (let i = 0; i < this.athletes.length; i++) {
        if (this.athletes[i].id == athlete_id) {
          return this.athletes[i].fullname;
        }
      }
      return "";
    },
    get_swimstats_query_from_athlete_id(athlete_id: string): string {
      for (let i = 0; i < this.athletes.length; i++) {
        if (this.athletes[i].id == athlete_id) {
          return this.athletes[i].lastname.toLocaleUpperCase() + ", " + this.athletes[i].firstname + ", ID:" + this.athletes[i].swrid;
        }
      }
      return "";
    },
    get_club_id_from_athlete_id(athlete_id: string | undefined): string | undefined {
      if (!athlete_id) {
        return undefined;
      }
      for (let i = 0; i < this.athletes.length; i++) {
        if (this.athletes[i].id == athlete_id) {
          return this.athletes[i].clubid;
        }
      }
      return undefined;
    },
    get_yob_from_athlete_id(athlete_id: string | undefined): number {
      if (!athlete_id) {
        return NaN;
      }
      for (let i = 0; i < this.athletes.length; i++) {
        if (this.athletes[i].id == athlete_id) {
          return this.athletes[i].yob;
        }
      }
      return NaN;
    },
    get_gender_from_athlete_id(athlete_id: string): string {
      for (let i = 0; i < this.athletes.length; i++) {
        if (this.athletes[i].id == athlete_id) {
          return this.athletes[i].gender;
        }
      }
      return "";
    },
    get_swrid_from_athlete_id(athlete_id: string): string | undefined {  // TODO(RoA) how to make it defined?
      for (let i = 0; i < this.athletes.length; i++) {
        if (this.athletes[i].id == athlete_id) {
          if ('swrid' in this.athletes[i]) {
            return this.athletes[i].swrid;
          }
        }
      }
      return "";
    },
    get_clubid_from_athlete_id(athlete_id: string): string {
      for (let i = 0; i < this.athletes.length; i++) {
        if (this.athletes[i].id == athlete_id) {
          return this.athletes[i].clubid;
        }
      }
      return "";
    },
    get_clubname_from_club_id(club_id: string | undefined): string | undefined {
      if (!club_id) {
        return undefined;
      }
      for (let i = 0; i < this.clubs.length; i++) {
        if (this.clubs[i].id == club_id) {
          return this.clubs[i].name;
        }
      }
      return undefined;
    },
    get_landing_page_from_event(event: BaseEvent): string {
      if (this.get_isrelay_from_event_id(event.id)) {
        if (event.status == 5 || event.status == 4) {
          return "Resultsrelay";
        }
        if (event.status == 2) {
          return "Heatsrelay";
        }
        if (event.status == 3) {
          return "Resultsrelay";
        }
        return "Entriesrelay";
      } else {
        if (event.status == 5 || event.status == 4) {
          return "Results";
        }
        if (event.status == 2) {
          return "Heats";
        }
        if (event.status == 3) {
          return "Results";
        }
        return "Entries";
      }
    },
    is_favorite(athlete_id?: string): boolean {
      if (!athlete_id) {
        return false
      }
      const base_athlete = this.get_base_athlete_from_athlete_id(athlete_id);
      if (base_athlete) {
        for (let i = 0; i < this.favorites.length; i++) {
          if ('swrid' in this.favorites[i] && this.favorites[i].swrid == base_athlete.swrid) {
            return true;
          }
          else if ((this.favorites[i].fullname == base_athlete.fullname) && (this.favorites[i].yob == base_athlete.yob)) {
            if ('swrid' in base_athlete && 'swrid' in this.favorites[i]) {
              this.$store.dispatch(
                "addFavorite",
                base_athlete
              );
            }
            return true;
          }
        }
      }
      return false;
    },
    get_base_athlete_from_favorite_base_athlete(base_athlete: BaseAthlete): BaseAthlete | undefined {
      for (let i = 0; i < this.athletes.length; i++) {
        if ('swrid' in base_athlete && 'swrid' in this.athletes[i] && base_athlete.swrid == this.athletes[i].swrid) {
          return this.athletes[i];
        }
        else if (base_athlete.fullname == this.athletes[i].fullname) {
          return this.athletes[i];
        }
      }
      return undefined;
    },
    get_base_athlete_from_athlete_id(athlete_id: string): BaseAthlete | undefined {
      for (let i = 0; i < this.athletes.length; i++) {
        if (this.athletes[i].id == athlete_id) {
          return this.athletes[i];
        }
      }
      return undefined;
    },
    exists_athlete_id(athlete_id: string): boolean {
      for (let i = 0; i < this.athletes.length; i++) {
        if (this.athletes[i].id == athlete_id) {
          return true;
        }
      }
      return false;
    },
    get_meet_image_src(i: number): string {
      if (i == 0) {
        return require("@/assets/koller/start_512.jpg");
      }
      if (i == 1) {
        return require("@/assets/koller/omega_512.jpg");
      }
      if (i == 2) {
        return require("@/assets/koller/competition_512.jpg");
      }
      if (i == 3) {
        return require("@/assets/koller/trainer_512.jpg");
      }
      return "";
    },
    get_formated_date_from_date(date: string): string {
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
      const d = new Date(date);
      return days[d.getDay()] + ', ' + d.getDate() + ' ' + monthNames[d.getMonth()] + ' ' + d.getFullYear();
    },
    get_year_fraction_from_ssdate(date: string){
      const p = new Date(date);
      return ((Date.UTC(p.getFullYear(), p.getMonth(), p.getDate()) - Date.UTC(p.getFullYear(), 0, 0)) /
      24 /
      60 /
      60 /
      1000 -
      1) /
      366 +
      p.getFullYear()
    },
    is_style_compatible_with_swimstats(style: string) :boolean{
      const supported_styles = ['50m Freestyle', '100m Freestyle', '200m Freestyle', '400m Freestyle', '800m Freestyle',
        '1500m Freestyle', '50m Backstroke', '100m Backstroke', '200m Backstroke', '50m Breaststroke',
        '100m Breaststroke', '200m Breaststroke', '50m Butterfly', '100m Butterfly', '200m Butterfly',
        '100m Medley', '200m Medley', '400m Medley']
      return supported_styles.includes(style)
    },
    update_meet() {
      this.$store.dispatch("setMain", this.$route.params.meetid);
      this.$store.dispatch("setAthletes", this.$route.params.meetid);
      this.$store.dispatch("setEvents", this.$route.params.meetid);
      this.$store.dispatch("setEventsBySession", this.$route.params.meetid);
      this.$store.dispatch("setAgegroups", this.$route.params.meetid);
      this.$store.dispatch("setClubs", this.$route.params.meetid);
    },
    scroll(id: string) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
    },
  },
  created() {
    if (!this.main) {
      this.$store.dispatch("setMain", this.$route.params.meetid);
    }
    if (!this.athletes) {
      this.$store.dispatch("setAthletes", this.$route.params.meetid);
    }
    if (!this.events) {
      this.$store.dispatch("setEvents", this.$route.params.meetid);
    }
    if (!this.eventsBySession) {
      this.$store.dispatch("setEventsBySession", this.$route.params.meetid);
    }
    if (!this.agegroups) {
      this.$store.dispatch("setAgegroups", this.$route.params.meetid);
    }
    if (!this.clubs) {
      this.$store.dispatch("setClubs", this.$route.params.meetid);
    }
  },
  computed: {
    main(): BaseMain {
      return this.$store.getters.getMain;
    },
    events() {
      return this.$store.getters.getEvents;
    },
    athletes(): Array<BaseAthlete> {
      return this.$store.getters.getAthletes;
    },
    eventsBySession(): BaseEventsBySessions {
      return this.$store.getters.getEventsBySession;
    },
    agegroups() {
      return this.$store.getters.getAgegroups;
    },
    clubs(): [BaseClub] {
      return this.$store.getters.getClubs;
    },
    favorites(): Array<BaseAthlete> {
      return this.$store.getters.getFavorites;
    },
    clubTab(): string {
      return this.$store.getters.getClubTab;
    },
    contentPaddingTop(): number {
      return this.$store.getters.getContentPaddingTop;
    }
  }
});
