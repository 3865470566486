<template>
  <NavigationTop :insetTop="insetTop" :navigationHeight="topNavigationHeight" />
  <Waves
    v-if="scrollpx == 0 && ($route.path.includes('/meets') || $route.path == '/')"
    class="waves-top"
    :isNativePlatform="isNativePlatform"
  />
  <router-view class="content" />
  <NavigationBottom
    :insetBottom="insetBottom"
    :navigationHeight="bottomNavigationHeight"
    :isIos="isIos"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Capacitor } from "@capacitor/core";
import NavigationTop from "@/components/NavigationTop.vue";
import NavigationBottom from "@/components/NavigationBottom.vue";
import Waves from "@/components/Waves.vue";

import { App as AppPlugin } from "@capacitor/app";
import { SafeArea } from "capacitor-plugin-safe-area";
import { Device, DeviceInfo } from "@capacitor/device";

export default defineComponent({
  name: "Home",
  components: {
    NavigationTop,
    NavigationBottom,
    Waves,
  },
  data: function () {
    return {
      version: "undef",
      topNavigationHeight: 50,
      bottomNavigationHeight: 45,
      insetTop: 0,
      insetBottom: 0,
      contentPaddingBottom: 0,
      localContentPaddingTop: 0,
      scrollpx: 0,
      isNativePlatform: false,
      isIos: false,
      isMobile: false,
      deviceInfo: (null as unknown) as DeviceInfo,
    };
  },
  methods: {
    handleScroll() {
      this.scrollpx = window.scrollY;
    },
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    this.$store.dispatch("initialiseStore");
    AppPlugin.addListener("backButton", () => {
      this.$router.go(-1);
    });

    Device.getInfo().then((deviceInfo) => {
      this.deviceInfo = deviceInfo;
      this.isMobile = ["ios", "android"].includes(this.deviceInfo.operatingSystem);
      this.isIos = ["ios"].includes(this.deviceInfo.operatingSystem);
    });

    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      if (this.isIos) {
        // Uncomment to overwrite
        // insets.bottom = 10;
        // insets.top = 30;
        this.insetTop = insets.top;
        this.insetBottom = insets.bottom;
      }
      this.contentPaddingBottom = this.insetBottom + this.bottomNavigationHeight * 2;
      this.localContentPaddingTop = this.insetTop + this.topNavigationHeight;
      this.$store.dispatch("setContentPaddingTop", this.localContentPaddingTop);
    });
    window.addEventListener("scroll", this.handleScroll);
    this.isNativePlatform = Capacitor.isNativePlatform();
  },
});
</script>

<style>
#app {
  font-family: "Segoe UI", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #202529;
  max-width: 800px;
  margin: 0px auto;
  background-color: #fcfcfc;
}

.content {
  padding-top: v-bind(localContentPaddingTop + "px");
  padding-bottom: v-bind(contentPaddingBottom + "px");
  margin: 5px;
}

.waves-inside {
  position: fixed;
  top: 200px;
  z-index: 5;
  margin: 5% auto;
  left: 0;
  right: 0;
}

.waves-top {
  position: sticky;
  top: 50px;
  z-index: 5;
  margin: 5% auto;
  left: 0;
  right: 0;
}
</style>
