
import { defineComponent } from "vue";
import { Capacitor } from "@capacitor/core";
import NavigationTop from "@/components/NavigationTop.vue";
import NavigationBottom from "@/components/NavigationBottom.vue";
import Waves from "@/components/Waves.vue";

import { App as AppPlugin } from "@capacitor/app";
import { SafeArea } from "capacitor-plugin-safe-area";
import { Device, DeviceInfo } from "@capacitor/device";

const __default__ = defineComponent({
  name: "Home",
  components: {
    NavigationTop,
    NavigationBottom,
    Waves,
  },
  data: function () {
    return {
      version: "undef",
      topNavigationHeight: 50,
      bottomNavigationHeight: 45,
      insetTop: 0,
      insetBottom: 0,
      contentPaddingBottom: 0,
      localContentPaddingTop: 0,
      scrollpx: 0,
      isNativePlatform: false,
      isIos: false,
      isMobile: false,
      deviceInfo: (null as unknown) as DeviceInfo,
    };
  },
  methods: {
    handleScroll() {
      this.scrollpx = window.scrollY;
    },
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    this.$store.dispatch("initialiseStore");
    AppPlugin.addListener("backButton", () => {
      this.$router.go(-1);
    });

    Device.getInfo().then((deviceInfo) => {
      this.deviceInfo = deviceInfo;
      this.isMobile = ["ios", "android"].includes(this.deviceInfo.operatingSystem);
      this.isIos = ["ios"].includes(this.deviceInfo.operatingSystem);
    });

    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      if (this.isIos) {
        // Uncomment to overwrite
        // insets.bottom = 10;
        // insets.top = 30;
        this.insetTop = insets.top;
        this.insetBottom = insets.bottom;
      }
      this.contentPaddingBottom = this.insetBottom + this.bottomNavigationHeight * 2;
      this.localContentPaddingTop = this.insetTop + this.topNavigationHeight;
      this.$store.dispatch("setContentPaddingTop", this.localContentPaddingTop);
    });
    window.addEventListener("scroll", this.handleScroll);
    this.isNativePlatform = Capacitor.isNativePlatform();
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "b85bdc36": (_ctx.localContentPaddingTop + "px"),
  "3ebd7254": (_ctx.contentPaddingBottom + "px")
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__