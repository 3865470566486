
import { defineComponent } from "vue";
import meetlookup from "../MeetLookup";

const __default__ = defineComponent({
  name: "NavigationTop",
  mixins: [meetlookup],
  props: {
    insetTop: {
      type: Number,
      required: true,
    },
    navigationHeight: {
      type: Number,
      required: true,
    },
  },
  methods: {
    isContained(mystr: string) {
      return this.$route.path.includes(mystr);
    },
    get_text() {
      if (
        "eventid" in this.$route.params &&
        String(this.$route.params.eventid) != "0"
      ) {
        return this.get_style_from_event_id(
          String(this.$route.params["eventid"])
        );
      }
      if ("clubid" in this.$route.params && this.clubs) {
        for (let idx = 0; idx < this.clubs.length; idx++) {
          if (this.clubs[idx].id == this.$route.params["clubid"]) {
            return this.clubs[idx].shortname;
          }
        }
      }

      return "";
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "11c1729e": (_ctx.insetTop + "px"),
  "4fb193ee": (_ctx.navigationHeight + "px")
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__