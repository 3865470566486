import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@/assets/global.css'
import VueGtag from "vue-gtag";


createApp(App).use(router).use(store).use(VueAxios, axios).use(VueGtag, {
    config: {
        id: "G-LP78HWGE4X"
    }
}).mount('#app')
