<template>
  <div class="top-bar"></div>
  <div class="navbar">
    <router-link to="/swimstats" style="color: red">swimstats live</router-link>
    <div style="padding-right:50px">
      <router-link style="padding-right:20px" v-if="isContained('/meets') || this.$route.path == '/'" to="/meets">Meets</router-link>
      <router-link style="padding-right:20px" v-else to="/meets">Meets</router-link>
    </div>
    <div class="swimstyle">
      <div class="move">{{ get_text() }}
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";
import meetlookup from "../MeetLookup";

export default defineComponent({
  name: "NavigationTop",
  mixins: [meetlookup],
  props: {
    insetTop: {
      type: Number,
      required: true,
    },
    navigationHeight: {
      type: Number,
      required: true,
    },
  },
  methods: {
    isContained(mystr: string) {
      return this.$route.path.includes(mystr);
    },
    get_text() {
      if (
        "eventid" in this.$route.params &&
        String(this.$route.params.eventid) != "0"
      ) {
        return this.get_style_from_event_id(
          String(this.$route.params["eventid"])
        );
      }
      if ("clubid" in this.$route.params && this.clubs) {
        for (let idx = 0; idx < this.clubs.length; idx++) {
          if (this.clubs[idx].id == this.$route.params["clubid"]) {
            return this.clubs[idx].shortname;
          }
        }
      }

      return "";
    },
  },
});
</script>


<style scoped>
.navbar {
  background-color: #333;
  overflow: hidden;
  position: fixed;
  top: v-bind(insetTop + "px");
  height: v-bind(navigationHeight + "px");
  width: 100%;
  max-width: 800px;
  text-align: center;
  z-index: 10;
}

a {
  color: grey;
  text-decoration: none;
}

/* Style the links inside the navigation bar */
.navbar a {
  float: left;
  display: block;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: v-bind(navigationHeight + "px");
  padding: 0px 7px 0px 7px;
}

.swimstyle {
  display: block;
  text-align: center;
  padding: 11px 20px 11px 20px;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.router-link-active {
  color: white;
  background-color: black;
}

.top-bar {
  height: v-bind(insetTop + "px");
  top: 0px;
  display: flex;
  background-color: rgb(179, 179, 179);
  position: sticky;
  text-align: center;
  z-index: 10;
}

.move {
  -moz-animation: marquee 10s linear infinite;
  -webkit-animation: marquee 10s linear infinite;
  animation: marquee 10s linear infinite;
}

@-moz-keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
  }

  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
</style>
