import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/swimstats_live_icon_512.jpg'


const _hoisted_1 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"inner-header\" data-v-dfb4d1d4><br data-v-dfb4d1d4><div class=\"flex\" data-v-dfb4d1d4><h1 data-v-dfb4d1d4>swimstats-live</h1></div><img style=\"height:60px;border-radius:4px;\" src=\"" + _imports_0 + "\" data-v-dfb4d1d4><div class=\"flex\" data-v-dfb4d1d4>meet companion with<br data-v-dfb4d1d4>progress analysis</div></div><div data-v-dfb4d1d4><svg class=\"waves\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 24 150 28\" preserveAspectRatio=\"none\" shape-rendering=\"auto\" data-v-dfb4d1d4><defs data-v-dfb4d1d4><path id=\"gentle-wave\" d=\"M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z\" data-v-dfb4d1d4></path></defs><g class=\"parallax\" data-v-dfb4d1d4><use xlink:href=\"#gentle-wave\" x=\"48\" y=\"0\" fill=\"rgba(255,255,255,0.7\" data-v-dfb4d1d4></use><use xlink:href=\"#gentle-wave\" x=\"48\" y=\"3\" fill=\"rgba(255,255,255,0.5)\" data-v-dfb4d1d4></use><use xlink:href=\"#gentle-wave\" x=\"48\" y=\"5\" fill=\"rgba(255,255,255,0.3)\" data-v-dfb4d1d4></use><use xlink:href=\"#gentle-wave\" x=\"48\" y=\"7\" fill=\"#fff\" data-v-dfb4d1d4></use></g></svg></div>", 2)
  ])))
}