import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { trackRouter } from "vue-gtag-next";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/meets",
    name: "Meetshome",
    props: true,
    component: () => import(/* webpackChunkName: "Meets"*/ "../views/Meets.vue")
  },
  {
    path: "/",
    name: "Meets",
    props: true,
    component: () => import(/* webpackChunkName: "Meets"*/ "../views/Meets.vue")
  },
  {
    path: "/meet/:meetid/meet",
    name: "Meet",
    props: true,
    component: () => import(/* webpackChunkName: "Meet"*/ "../views/Meet.vue")
  },
  {
    path: "/meet/:meetid/live",
    name: "Live",
    props: true,
    component: () => import(/* webpackChunkName: "Live"*/ "../views/Live.vue")
  },
  {
    path: "/meet/:meetid/entries/:eventid/club/:clubid/athlete/:athleteid",
    name: "Entries",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Entries"*/ "../views/Entries.vue")
  },
  {
    path: "/meet/:meetid/entriesrelay/:eventid/club/:clubid/athlete/:athleteid",
    name: "Entriesrelay",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Entriesrelay"*/ "../views/Entriesrelay.vue")
  },
  {
    path: "/meet/:meetid/heats/:eventid/club/:clubid/athlete/:athleteid",
    name: "Heats",
    props: true,
    component: () => import(/* webpackChunkName: "Heats"*/ "../views/Heats.vue")
  },
  {
    path: "/meet/:meetid/heatsrelay/:eventid/club/:clubid/athlete/:athleteid",
    name: "Heatsrelay",
    props: true,
    component: () => import(/* webpackChunkName: "Heatsrelay"*/ "../views/Heatsrelay.vue")
  },
  {
    path: "/meet/:meetid/results/:eventid/club/:clubid/athlete/:athleteid",
    name: "Results",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Results"*/ "../views/Results.vue")
  },
  {
    path: "/meet/:meetid/resultsrelay/:eventid/club/:clubid/athlete/:athleteid",
    name: "Resultsrelay",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Resultsrelay"*/ "../views/Resultsrelay.vue")
  },
  {
    path: "/meet/:meetid/event/:eventid/club/:clubid/athlete/:athleteid",
    name: "Result",
    props: true,
    component: () => import(/* webpackChunkName: "Result"*/ "../views/Result.vue")
  },
  {
    path: "/meet/:meetid/club/:clubid/event/:eventid/teamnumber/:teamnumber/athlete/:athleteid",
    name: "Resultrelay",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Resultrelay"*/ "../views/Resultrelay.vue")
  },
  {
    path: "/meet/:meetid/club/:clubid/athlete/:athleteid/event/:eventid",
    name: "Club",
    props: true,
    component: () => import(/* webpackChunkName: "Club"*/ "../views/Club.vue")
  },
  {
    path: "/meet/:meetid/club/:clubid/athlete/:athleteid/event/:eventid",
    name: "Athlete",
    props: true,
    component: () => import(/* webpackChunkName: "Athlete"*/ "../views/Athlete.vue")
  },
  {
    path: "/meet/:meetid/clubs",
    name: "Clubs",
    props: true,
    component: () => import(/* webpackChunkName: "Clubs"*/ "../views/Clubs.vue")
  },
  {
    path: "/meet/:meetid/schedule",
    name: "Schedule",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Schedule"*/ "../views/Schedule.vue")
  },
  {
    path: "/meet/:meetid/favorites/club/0/athlete/0/event/0",
    name: "Favorites",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Favorites"*/ "../views/Favorites.vue")
  },
  {
    path: "/swimstats",
    name: "Swimstats",
    component: () =>
      import(/* webpackChunkName: "Swimstats" */ "../views/Swimstats.vue")
  },
  {
    path: "/meet/:meetid/pointscore/:pointscoreid",
    name: "Pointscore",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Pointscore" */ "../views/Pointscore.vue")
  },
]
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
};
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
})
trackRouter(router)
export default router
