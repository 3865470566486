
import { defineComponent } from "vue";
import meetlookup from "../MeetLookup";

const __default__ = defineComponent({
  name: "NavigationBottom",
  mixins: [meetlookup],
  data: function () {
    return {
      showNavbar: false,
      lastScrollPosition: 0,
    };
  },
  props: {
    insetBottom: {
      type: Number,
      required: true,
    },
    navigationHeight: {
      type: Number,
      required: true,
    },
    isIos: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    isContained(mystr: string) {
      return this.$route.path.includes(mystr);
    },
    goBack() {
      this.$router.go(-1);
    },
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return;
      }
      // Here we determine whether we need to show or hide the navbar
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      // Set the current scroll position as the last scroll position
      this.lastScrollPosition = currentScrollPosition;
    },
    get_club_name() {
      if ("clubid" in this.$route.params && this.clubs) {
        for (let idx = 0; idx < this.clubs.length; idx++) {
          if (this.clubs[idx].id == this.$route.params["clubid"]) {
            return this.clubs[idx].shortname;
          }
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "81ca8594": (_ctx.insetBottom + "px"),
  "46ccfbb4": (_ctx.insetBottom + _ctx.navigationHeight + "px"),
  "16c8413e": (_ctx.navigationHeight + "px")
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__